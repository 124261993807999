import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/env-provider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/use-env-context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/_components/Footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/_components/Header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/_components/Main/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/_style/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/pageProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/StyleRegistry.tsx");
